import React,{ Fragment, useState } from "react";
import API from "../../Api";
import Card from "../UI/Card/Card";
import Loader from "../UI/Loader/Loader";
import classes from "./ResultCard.module.css";
import TicketQry from "./TicketQry";
import { format } from "date-fns";
import ViewResult from "./ViewResult";

const ResultCard = (props) => {
  const lotteryArr = props.LotteryDetails.data;
  const callFrom = props.callFrom;
  const [ticketQuery, setTicketQuery] = useState("");
  const [showResult, setShowResult] = useState(lotteryArr[0].lotcode);
  const [isLoading, setIsLoading] = useState(false);
  
  const showTicketQry = (e,index) => {
    e.stopPropagation();
    console.log("showTicketQry",index);
    setTicketQuery(index);
    setShowResult(false);
  };
  const handleCardClick = (lotcode) => {
    console.log("handleCardClick",lotcode);
    setShowResult(lotcode);
    setTicketQuery(false);
  };

  const downloadResultPDF = (e,drawdate, lotcode, lotname) => {
    setIsLoading(true);
    e.stopPropagation();
    let uri = `https://myrajshree.com/uat-result-api/index.php/get-result-pdf/${drawdate}/${lotcode}/${lotname}`;
    window.open(uri, '_blank');
    setIsLoading(false);
  };

  const downloadResultImage = (e,drawdate, lotcode, lotname) => {
    setIsLoading(true);
    e.stopPropagation();
    let uri = "https://myrajshree.com/uat-result-api/index.php/v2/get-result-img/" + drawdate + "/" + lotcode;
    window.open(uri, '_blank');
    setIsLoading(false);
  };

  console.log(callFrom);
  return (
    <Fragment>
      {isLoading ? <Loader /> : ""}
      {lotteryArr.map((lottery, index) => (
        <Card
          className={`${classes.resultCard}`}
          key={`card-${lottery.lotcode}`}
        >
          <div
            className={`row ${classes.resultCard__header} ${
              callFrom === "TODAY" && index === 0 && classes.active
            }`}
            onClick={() => handleCardClick(lottery.lotcode)}
          >
            <div className={`col-lg-5 col-md-6 col-4`}>
              <h3
                className={`${classes.resultCard_lotteryname} ${
                  callFrom === "TODAY" && index === 0 && classes.active
                }`}
              >
                {lottery.lotname}
              </h3>
            </div>
            <div className={`col-lg-2 col-md-6 col-8 text-right`}>
              <h3
                className={`${classes.resultCard_drawtime} ${
                  callFrom === "TODAY" && index === 0 && classes.active
                }`}
              >
                {lottery.drawtime}
              </h3>
            </div>
            <div className={`col-lg-5 col-md-12 col-12 text-lg-right`}>
              <input
                type="submit"
                className={`btn ${classes.bttn} ${
                  callFrom === "TODAY" && index === 0 && classes.active
                }`}
                value="PDF"
                id={"pdf-" + lottery.lotcode}
                onClick={(e) =>
                  downloadResultPDF(
                    e,
                    lottery.drawdate,
                    lottery.lotcode,
                    lottery.lotname
                  )
                }
              />
              {lottery.hasimage === 1 ? (
                <input
                  type="submit"
                  className={`btn ${classes.bttn} ${
                    callFrom === "TODAY" && index === 0 && classes.active
                  }`}
                  value="JPEG"
                  id={"img-" + lottery.lotcode}
                  onClick={(e) =>
                    downloadResultImage(
                      e,
                      lottery.drawdate,
                      lottery.lotcode,
                      lottery.lotname
                    )
                  }
                />
              ) : (
                ""
              )}

              <input
                type="submit"
                className={`btn ${classes.bttn1} ${
                  callFrom === "TODAY" && index === 0 && classes.active
                }`}
                value="Verify Ticket"
                onClick={(e) => showTicketQry(e,lottery.lotcode)}
              />
            </div>
          </div>
          {showResult === lottery.lotcode && (
             <div>
              <MemoizedViewResult drawdate={lottery.drawdate} lotcode={lottery.lotcode} lotname={lottery.lotname} hideShow={handleCardClick}/>
            </div>
          )}
          {ticketQuery === lottery.lotcode ? (
            <TicketQry
              cardDetails={{
                lotcode: lottery.lotcode,
                series: lottery.series_length,
                number: lottery.number_length,
                drawdate: lottery.drawdate,
                series_type: lottery.series_type,
                lotname: lottery.lotname,
              }}
              HideDiv={showTicketQry}
            />
          ) : (
            ""
          )}
        </Card>
      ))}
    </Fragment>
  );
};
const MemoizedViewResult = React.memo(ViewResult);

export default ResultCard;
